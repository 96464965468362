import React, { useState } from 'react';

function ExternalProductForm({ addProduct }) {
  const [productName, setProductName] = useState('');
  const [productUrl, setProductUrl] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (productName.trim() === '') {
      alert('Bitte Produktname eingeben.');
      return;
    }
    addProduct({ id: Date.now(), name: productName.trim(), url: productUrl.trim() });
    setProductName('');
    setProductUrl('');
  };

  return (
    <form onSubmit={handleSubmit}>
      
      <input
        className="awp-input"
        type="text"
        placeholder="Produktname"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
      />
      <input
        className="awp-input"
        type="url"
        placeholder="Produktlink (optional)"
        value={productUrl}
        onChange={(e) => setProductUrl(e.target.value)}
      />
      <button className="awp-button" type="submit">Hinzufügen</button>
    </form>
  );
}

export default ExternalProductForm;

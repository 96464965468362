// src/components/TempProductsManager.js
import React, { useState } from 'react';
import { Plus, X, ArrowRight } from 'lucide-react';

const TempProductsManager = ({
  tempProducts,
  wishlists,
  onMove,
  onClose,
  onCreateList
}) => {
  const [selectedList, setSelectedList] = useState('');
  const [newListName, setNewListName] = useState('');
  const hasLists = Object.keys(wishlists).filter(list => list !== '_temp_products').length > 0;

  const handleMove = () => {
    if (selectedList) {
      onMove(tempProducts, '_temp_products', selectedList);
    }
  };

  const handleCreateList = () => {
    if (newListName.trim()) {
      onCreateList(newListName.trim());
      setNewListName('');
    }
  };

  return (
    <div className="awp-temp-products-modal">
      <div className="awp-temp-products-content">
        <div className="awp-modal-header">
          <h2 className="awp-modal-title">Nicht zugeordnete Produkte</h2>
          <button
            onClick={onClose}
            className="awp-modal-close"
            aria-label="Schließen"
          >
            <X size={20} />
          </button>
        </div>

        <div className="awp-notice awp-notice-info">
          <p>Sie haben {tempProducts.length} Produkt(e), die noch keiner Liste zugeordnet sind.</p>
        </div>

        <div className="awp-products-list">
          {tempProducts.map(product => (
            <div key={product.id} className="awp-product-item">
              <div className="awp-product-details">
                <h3 className="awp-product-name">{product.name}</h3>
                {product.url && (
                  <a
                    href={product.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="awp-product-link"
                  >
                    Zum Produkt
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>

        {hasLists ? (
          <div className="awp-action-section">
            <div className="awp-action-group">
              <label className="awp-label">Einer vorhandenen Liste zuordnen</label>
              <div className="awp-select-group">
                <select
                  value={selectedList}
                  onChange={(e) => setSelectedList(e.target.value)}
                  className="awp-select"
                >
                  <option value="">Liste auswählen</option>
                  {Object.keys(wishlists)
                    .filter(list => list !== '_temp_products')
                    .map(list => (
                      <option key={list} value={list}>{list}</option>
                    ))}
                </select>
                <button
                  onClick={handleMove}
                  disabled={!selectedList}
                  className="awp-button awp-button-primary"
                >
                  <ArrowRight size={20} />
                  Verschieben
                </button>
              </div>
            </div>

            <div className="awp-divider">
              <span>Oder</span>
            </div>
          </div>
        ) : (
          <div className="awp-notice awp-notice-warning">
            <p>Es existieren noch keine Listen. Bitte erstellen Sie zuerst eine neue Liste.</p>
          </div>
        )}

        <div className="awp-create-list-section">
          <label className="awp-label">Neue Liste erstellen</label>
          <div className="awp-create-group">
            <input
              type="text"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="Bezeichnung für neue Liste"
              className="awp-input"
            />
            <button
              onClick={handleCreateList}
              disabled={!newListName.trim()}
              className="awp-button awp-button-primary"
            >
              <Plus size={20} />
              Erstellen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempProductsManager;

// src/components/ProductSearch.js

import React, { useState } from 'react';
import { Search, Plus, Loader } from 'lucide-react';

function ProductSearch({ addProduct, disabled }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchProducts = () => {
    if (searchTerm.trim() === '') {
      alert('Bitte geben Sie einen Suchbegriff ein.');
      return;
    }

    setIsLoading(true);

    const params = new URLSearchParams({
      action: 'awp_search_products',
      term: searchTerm.trim(),
      nonce: window.awpGlobal.nonce
    });

    fetch(`${window.awpGlobal.ajax_url}?${params.toString()}`)
      .then(res => res.json())
      .then(data => {
        if (data.success === false) {
          throw new Error(data.data || 'Fehler bei der Suche');
        }

        // Anpassung hier: Prüfen, ob data.data existiert und ein Array ist
        const products = Array.isArray(data.data)
          ? data.data
          : Array.isArray(data)
          ? data
          : [];

        setResults(products);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Suchfehler:', error);
        alert('Fehler bei der Produktsuche.');
        setIsLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchProducts();
    }
  };

  const handleAddProduct = (product) => {
    const formattedProduct = {
      id: product.id,
      name: product.name,
      url: product.url,
      imageUrl: product.imageUrl,
      price: product.price
    };
    addProduct(formattedProduct);
    setResults([]);
    setSearchTerm('');
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="Produktname eingeben..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={disabled || isLoading}
          className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
          aria-label="Produkt suchen"
        />
        {isLoading && (
          <Loader
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 animate-spin"
            size={20}
          />
        )}
      </div>

      {results.length > 0 && (
        <div className="border border-gray-200 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
          {results.map(product => (
            <div
              key={product.id}
              className="flex items-center justify-between p-4 hover:bg-gray-50 transition-colors"
            >
              <div className="flex items-center gap-4">
                {product.imageUrl && (
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="w-16 h-16 object-cover rounded-lg border border-gray-200"
                  />
                )}
                <div>
                  <a
                    href={product.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-800 hover:text-red-500 font-medium transition-colors"
                  >
                    {product.name}
                  </a>
                  {product.price && (
                    <div
                      className="text-red-500 text-sm mt-1"
                      dangerouslySetInnerHTML={{ __html: product.price }}
                    />
                  )}
                </div>
              </div>
              <button
                onClick={() => handleAddProduct(product)}
                className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
                disabled={disabled}
                aria-label={`Produkt ${product.name} hinzufügen`}
              >
                <Plus size={20} />
              </button>
            </div>
          ))}
        </div>
      )}

      {results.length === 0 && searchTerm && !isLoading && (
        <div className="text-center py-8 text-gray-500">
          Keine Ergebnisse gefunden.
        </div>
      )}
    </div>
  );
}

export default ProductSearch;

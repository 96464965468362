// Wishlist.js
import React, { useState } from 'react';
import { ExternalLink, ArrowRight, Share2, ClipboardCopy, Gift } from 'lucide-react';

const Wishlist = ({ items, wishlists, currentList, moveProduct, wishlistCode, listName }) => {
  const [showShareLink, setShowShareLink] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);

  const generateShareableLink = () => {
    const baseUrl = `${window.awpGlobal.site_url}/wunschliste/shared/${encodeURIComponent(wishlistCode)}`;
    if (listName && listName !== '_temp_products') {
      return `${baseUrl}?occasion=${encodeURIComponent(listName)}`;
    }
    return baseUrl;
  };

  const copyShareableLink = () => {
    const link = generateShareableLink();
    navigator.clipboard.writeText(link)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch(() => {
        setError('Link konnte nicht kopiert werden');
      });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-serif text-gray-800">{listName}</h2>
        <button
          onClick={() => setShowShareLink(!showShareLink)}
          className="p-2 text-gray-400 hover:text-red-500 rounded-full transition-colors"
          title="Liste teilen"
          aria-label="Liste teilen"
        >
          <Share2 size={20} />
        </button>
      </div>

      {showShareLink && (
        <div className="bg-red-50 rounded-lg p-4 space-y-2">
          <label className="text-gray-700 font-medium block">
            Teilen Sie diese Liste:
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={generateShareableLink()}
              readOnly
              onFocus={(e) => e.target.select()}
              className="flex-1 px-4 py-2 border border-gray-200 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
              aria-label="Teilen-Link kopieren"
            />
          </div>
          {isCopied && (
            <span className="text-green-600 text-sm">Link wurde kopiert!</span>
          )}
          {error && (
            <span className="text-red-500 text-sm">{error}</span>
          )}
        </div>
      )}

      {items.length === 0 ? (
        <div className="text-center py-12">
          <Gift className="h-16 w-16 text-gray-300 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-800 mb-2">
            Keine Wünsche in dieser Liste
          </h3>
          <p className="text-gray-500">
            Fügen Sie Ihre Wünsche hinzu und teilen Sie sie mit Ihren Liebsten
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {items.map(item => (
            <div
              key={item.id}
              className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 hover:shadow-md transition-shadow"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  {item.imageUrl && (
                    <img
                      src={item.imageUrl}
                      alt={item.productName || item.name}
                      className="w-16 h-16 object-cover rounded-lg border border-gray-200"
                    />
                  )}
                  <div>
                    <h3 className="font-medium text-gray-800">
                      {item.productName || item.name}
                    </h3>
                    {item.description && (
                      <p className="text-gray-500 text-sm mt-1">{item.description}</p>
                    )}
                    {(item.productUrl || item.url) && (
                      <a
                        href={item.productUrl || item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-red-500 hover:text-red-600 text-sm mt-2"
                      >
                        <ExternalLink size={16} />
                        <span>Zum Produkt</span>
                      </a>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <select
                    onChange={(e) => {
                      const toList = e.target.value;
                      if (toList) {
                        moveProduct(item.id, currentList, toList);
                      }
                      e.target.value = '';
                    }}
                    className="px-3 py-1 border border-gray-200 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                    aria-label={`Produkt ${item.productName || item.name} verschieben`}
                  >
                    <option value="">Verschieben nach...</option>
                    {Object.keys(wishlists)
                      .filter(list => list !== currentList && list !== '_temp_products')
                      .map(list => (
                        <option key={list} value={list}>{list}</option>
                      ))}
                  </select>
                  <ArrowRight size={16} className="text-gray-400" />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Wishlist;

// wp-content/plugins/advanced-wishlist-plugin/react-app/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WishlistManager from './components/WishlistManager';
import SharedWishlist from './components/SharedWishlist';

function App() {
    return (
        <Router basename="/wunschliste">
            <Routes>
                <Route path="/" element={<WishlistManager />} />
                <Route path="/shared/:code" element={<SharedWishlist />} />
                {/* Der :code Parameter fängt den Code-Teil der URL auf */}
                {/* Der occasion Query-Parameter wird automatisch verfügbar */}
            </Routes>
        </Router>
    );
}

export default App;

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Wishlist from './Wishlist';
import ExternalProductForm from './ExternalProductForm';
import ProductSearch from './ProductSearch';
import TempProductsManager from './TempProductsManager';
import { Gift, Search, ClipboardCopy, LogOut, ExternalLink } from 'lucide-react';
import '../WishlistManager.css';

// StorageManager für die lokale Datenspeicherung
const StorageManager = {
    storageKey: 'awp_active_wishlists',
    tempProductKey: 'awp_temp_product',

    saveActiveWishlist(code, lists) {
        const data = {
            code,
            lists,
            timestamp: Date.now()
        };
        try {
            localStorage.setItem(this.storageKey, JSON.stringify(data));
            return true;
        } catch (error) {
            console.error('Fehler beim Speichern in localStorage:', error);
            return false;
        }
    },

    getActiveWishlist() {
        try {
            const data = localStorage.getItem(this.storageKey);
            if (!data) return null;

            const parsed = JSON.parse(data);
            const oneDay = 24 * 60 * 60 * 1000;
            if (Date.now() - parsed.timestamp > oneDay) {
                this.clearActiveWishlist();
                return null;
            }
            return parsed;
        } catch (error) {
            console.error('Fehler beim Lesen aus localStorage:', error);
            return null;
        }
    },

    clearActiveWishlist() {
        try {
            localStorage.removeItem(this.storageKey);
            return true;
        } catch (error) {
            console.error('Fehler beim Löschen aus localStorage:', error);
            return false;
        }
    },

    saveTempProduct(product) {
        const data = {
            product,
            timestamp: Date.now()
        };
        try {
            localStorage.setItem(this.tempProductKey, JSON.stringify(data));
            return true;
        } catch (error) {
            console.error('Fehler beim Speichern des temporären Produkts:', error);
            return false;
        }
    },

    getTempProduct() {
        try {
            const data = localStorage.getItem(this.tempProductKey);
            if (!data) return null;

            const parsed = JSON.parse(data);
            const oneHour = 60 * 60 * 1000;
            if (Date.now() - parsed.timestamp > oneHour) {
                this.clearTempProduct();
                return null;
            }
            return parsed.product;
        } catch (error) {
            console.error('Fehler beim Lesen des temporären Produkts:', error);
            return null;
        }
    },

    clearTempProduct() {
        try {
            localStorage.removeItem(this.tempProductKey);
            return true;
        } catch (error) {
            console.error('Fehler beim Löschen des temporären Produkts:', error);
            return false;
        }
    }
};

function WishlistManager() {
    // State Management
    const [code, setCode] = useState('');
    const [wishlists, setWishlists] = useState({});
    const [currentList, setCurrentList] = useState('');
    const [newOccasion, setNewOccasion] = useState('');
    const [generatedCode, setGeneratedCode] = useState('');
    const [showTempProductsModal, setShowTempProductsModal] = useState(false);
    const [tempProducts, setTempProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [hasPendingProduct, setHasPendingProduct] = useState(false);
    const [inputCode, setInputCode] = useState('');

    const location = useLocation();

    // Funktion zum Generieren eines eindeutigen Codes
    const generateUniqueCode = useCallback(() => {
        const timestamp = Date.now().toString(36);
        const randomStr = Math.random().toString(36).substring(2, 8).toUpperCase();
        return `${timestamp}${randomStr}`.toUpperCase().slice(0, 8);
    }, []);

    // Funktion zum Kopieren in die Zwischenablage
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        } catch (err) {
            setError('Text konnte nicht kopiert werden');
        }
    };

    // Funktion zum Parsen von URL-Parametern
    const getURLParams = () => {
        const params = new URLSearchParams(location.search);
        return {
            code: params.get('awp_code'),
            occasion: params.get('occasion')
        };
    };

    // Initialisierung der Wunschliste
    useEffect(() => {
        const initializeWishlist = async () => {
            if (isInitialized) return;

            setIsInitialized(true);
            const { code: urlCode, occasion: urlOccasion } = getURLParams();

            try {
                if (urlCode) {
                    await loadWishlist(urlCode, urlOccasion);
                } else {
                    const activeWishlist = StorageManager.getActiveWishlist();
                    if (activeWishlist) {
                        await loadWishlist(activeWishlist.code);
                    }
                }
            } catch (error) {
                console.error('Fehler bei der Initialisierung:', error);
                setError('Fehler beim Laden der Wunschliste');
            }
        };

        initializeWishlist();
    }, [location.search, isInitialized]);

    // Funktion zum Laden der Wunschliste mit optionalem Anlass
    const loadWishlist = async (loadCode, loadOccasion = null) => {
        if (!loadCode) {
            setError('Bitte gib einen gültigen Code ein.');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const response = await fetch(window.awpGlobal.ajax_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    action: 'awp_handle_wishlist',
                    action_type: 'load',
                    code: loadCode,
                    nonce: window.awpGlobal.nonce
                }),
            });

            const data = await response.json();

            if (data.success) {
                setCode(loadCode);
                setWishlists(data.data);
                StorageManager.saveActiveWishlist(loadCode, data.data);

                const tempProduct = StorageManager.getTempProduct();
                if (tempProduct) {
                    await handleTempProduct();
                }

                if (data.data._temp_products?.length > 0) {
                    setTempProducts(data.data._temp_products);
                    setShowTempProductsModal(true);
                }

                let firstList = Object.keys(data.data).find(list => list !== '_temp_products') || '';
                if (loadOccasion && data.data[loadOccasion]) {
                    firstList = loadOccasion;
                }
                setCurrentList(firstList);
                setGeneratedCode(loadCode);
            } else {
                throw new Error(data.data || 'Keine Wunschliste gefunden.');
            }
        } catch (error) {
            setError(error.message);
            if (!code) {
                await createWishlist();
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Funktion zum Erstellen einer neuen Wunschliste
    const createWishlist = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const newCode = generateUniqueCode();
            const tempProduct = StorageManager.getTempProduct();

            const initialData = {
                _temp_products: tempProduct ? [tempProduct] : []
            };

            await saveWishlist(newCode, initialData);

            setCode(newCode);
            setGeneratedCode(newCode);
            setWishlists(initialData);
            setCurrentList(''); // currentList bleibt leer, damit der Benutzer einen neuen Anlass erstellen kann

            if (tempProduct) {
                setTempProducts([tempProduct]);
                setShowTempProductsModal(true);
                StorageManager.clearTempProduct();
            }

            return newCode;
        } catch (error) {
            setError('Fehler beim Erstellen der Wunschliste: ' + error.message);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    // Funktion zum Speichern der Wunschliste
    const saveWishlist = async (saveCode, saveWishlists) => {
        try {
            const response = await fetch(window.awpGlobal.ajax_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    action: 'awp_handle_wishlist',
                    action_type: 'save',
                    code: saveCode,
                    data: JSON.stringify(saveWishlists),
                    nonce: window.awpGlobal.nonce
                }),
            });

            const data = await response.json();

            if (!data.success) {
                throw new Error(data.data || 'Fehler beim Speichern.');
            }

            StorageManager.saveActiveWishlist(saveCode, saveWishlists);
            return true;
        } catch (error) {
            throw new Error('Fehler beim Speichern der Wunschliste: ' + error.message);
        }
    };

    // Funktion zum Hinzufügen eines Anlasses
    const addOccasion = async (occasionName = newOccasion) => {
        const trimmedOccasion = occasionName.trim();

        if (!trimmedOccasion) {
            setError('Bitte gib einen gültigen Anlass ein.');
            return null;
        }

        if (wishlists[trimmedOccasion]) {
            setError('Dieser Anlass existiert bereits.');
            return null;
        }

        try {
            setIsLoading(true);
            setError(null);

            const updatedWishlists = {
                ...wishlists,
                [trimmedOccasion]: []
            };

            await saveWishlist(code, updatedWishlists);
            setWishlists(updatedWishlists);
            setCurrentList(trimmedOccasion);
            setNewOccasion('');

            return trimmedOccasion;
        } catch (error) {
            setError('Fehler beim Erstellen des Anlasses: ' + error.message);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    // Funktion zum Verschieben von Produkten
    const moveProducts = async (products, fromList, toList) => {
        if (!toList || !wishlists[toList]) {
            setError('Ungültige Zielliste');
            return false;
        }

        try {
            setIsLoading(true);
            setError(null);

            const updatedWishlists = { ...wishlists };

            updatedWishlists[toList] = [
                ...(updatedWishlists[toList] || []),
                ...products
            ];

            if (fromList) {
                updatedWishlists[fromList] = updatedWishlists[fromList].filter(
                    product => !products.find(p => p.id === product.id)
                );
            }

            await saveWishlist(code, updatedWishlists);
            setWishlists(updatedWishlists);

            if (fromList === '_temp_products' &&
                (!updatedWishlists._temp_products || updatedWishlists._temp_products.length === 0)) {
                setShowTempProductsModal(false);
                setTempProducts([]);
            }

            return true;
        } catch (error) {
            setError('Fehler beim Verschieben der Produkte: ' + error.message);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    // Funktion zum Verschieben eines einzelnen Produkts
    const moveProduct = async (productId, fromList, toList) => {
        const product = wishlists[fromList]?.find(p => p.id === productId);
        if (!product) {
            setError('Produkt nicht gefunden');
            return;
        }

        await moveProducts([product], fromList, toList);
    };

    // Funktion zum Hinzufügen eines Produkts
    const addProduct = async (product) => {
        if (!currentList) {
            setError('Keine Liste ausgewählt');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const updatedWishlists = { ...wishlists };
            updatedWishlists[currentList] = [
                ...(updatedWishlists[currentList] || []),
                product
            ];

            await saveWishlist(code, updatedWishlists);
            setWishlists(updatedWishlists);
        } catch (error) {
            setError('Fehler beim Hinzufügen des Produkts: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Funktion zum Löschen einer Liste
    const deleteList = async (listName) => {
        if (listName === '_temp_products') return;

        if (!window.confirm(`Möchtest du die Liste "${listName}" wirklich löschen?`)) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const updatedWishlists = { ...wishlists };
            delete updatedWishlists[listName];

            await saveWishlist(code, updatedWishlists);
            setWishlists(updatedWishlists);

            if (currentList === listName) {
                const firstList = Object.keys(updatedWishlists)
                    .find(list => list !== '_temp_products') || '';
                setCurrentList(firstList);
            }
        } catch (error) {
            setError('Fehler beim Löschen der Liste: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Handler für temporäre Produkte
    const handleTempProduct = async () => {
        const tempProduct = StorageManager.getTempProduct();
        if (!tempProduct) return;

        try {
            setIsLoading(true);
            setHasPendingProduct(true);

            const updatedWishlists = { ...wishlists };
            if (!updatedWishlists._temp_products) {
                updatedWishlists._temp_products = [];
            }

            updatedWishlists._temp_products.push(tempProduct);

            await saveWishlist(code, updatedWishlists);
            setWishlists(updatedWishlists);
            setTempProducts(prev => [...prev, tempProduct]);
            setShowTempProductsModal(true);

            StorageManager.clearTempProduct();
            setHasPendingProduct(false);
        } catch (error) {
            setError('Fehler beim Hinzufügen des temporären Produkts: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Handler für das Erstellen einer neuen Liste aus dem TempProductsManager
    const handleCreateListFromTemp = async (listName) => {
        const newList = await addOccasion(listName);
        if (newList && tempProducts.length > 0) {
            await moveProducts(tempProducts, '_temp_products', newList);
        }
    };

    // Funktion zum Zurücksetzen der Wunschliste (Logout)
    const resetWishlist = useCallback(() => {
        StorageManager.clearActiveWishlist();
        StorageManager.clearTempProduct();
        setCode('');
        setWishlists({});
        setCurrentList('');
        setGeneratedCode('');
        setTempProducts([]);
        setShowTempProductsModal(false);
        setHasPendingProduct(false);
        window.location.href = '/wunschliste';
    }, []);

    // Error Cleanup
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    // Storage Sync
    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === StorageManager.storageKey) {
                const activeWishlist = StorageManager.getActiveWishlist();
                if (!activeWishlist && code) {
                    resetWishlist();
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [code, resetWishlist]);

    // Warnung vor dem Verlassen der Seite
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (wishlists._temp_products?.length > 0 || hasPendingProduct) {
                const message = 'Du hast noch nicht zugeordnete Produkte. Möchtest du die Seite wirklich verlassen?';
                e.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [wishlists._temp_products, hasPendingProduct]);

    // Header Rendering mit Abmelde-Icon
    const renderHeader = () => (
        <header className="bg-white shadow-md">
            <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center gap-3">
                    <Gift className="h-8 w-8 text-orange-500" />
                    <h1 className="text-3xl font-serif text-neutral-800">Meine Wunschliste</h1>
                </div>
                <div className="flex items-center gap-4">
                    {code && (
                        <div className="flex items-center bg-orange-50 rounded-lg px-4 py-2">
                            <span className="text-neutral-600 mr-2">Code:</span>
                            <span className="font-medium text-neutral-800">{generatedCode}</span>
                            <button
                                onClick={() => copyToClipboard(generatedCode)}
                                className="ml-2 p-1 hover:bg-orange-100 rounded-full transition-colors"
                                disabled={isLoading}
                                title="Code kopieren"
                            >
                                <ClipboardCopy className="h-5 w-5 text-orange-500" />
                            </button>
                            {isCopied && <span className="ml-2 text-sm text-orange-600">Kopiert!</span>}
                        </div>
                    )}
                    <button
                        onClick={resetWishlist}
                        className="p-2 hover:bg-orange-50 rounded-full transition-colors"
                        title="Abmelden"
                        aria-label="Abmelden"
                        disabled={isLoading}
                    >
                        <LogOut className="h-5 w-5 text-neutral-600" />
                    </button>
                </div>
            </div>
        </header>
    );

    // Hauptbereich Rendering
    const renderMainSection = () => (
        <div className="bg-white rounded-xl shadow-sm p-8">
            {currentList ? (
                <>
                    {wishlists[currentList]?.length > 0 ? (
                        <Wishlist
                            items={wishlists[currentList]}
                            wishlists={wishlists}
                            currentList={currentList}
                            moveProduct={moveProduct}
                            wishlistCode={code}
                            listName={currentList}
                            readOnly={false}
                        />
                    ) : (
                        <div className="text-center py-12">
                            <Gift className="h-16 w-16 text-neutral-300 mx-auto mb-4" />
                            <h3 className="text-lg font-medium text-neutral-800 mb-2">
                                Keine Wünsche in dieser Liste
                            </h3>
                            <p className="text-neutral-500 mb-6">
                                Füge deine Wünsche hinzu und teile sie mit deinen Liebsten
                            </p>
                        </div>
                    )}
                </>
            ) : code ? (
                // Falls eine Wunschliste erstellt wurde, aber noch kein Anlass vorhanden ist
                <div className="text-center py-12">
                    <Gift className="h-16 w-16 text-neutral-300 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-neutral-800 mb-2">
                        Kein Anlass erstellt
                    </h3>
                    <p className="text-neutral-500 mb-6">
                        Bitte erstelle einen neuen Anlass, um Produkte hinzuzufügen.
                    </p>
                    <div className="max-w-md mx-auto">
                        <div className="space-y-2">
                            <input
                                type="text"
                                placeholder="Anlass eingeben"
                                value={newOccasion}
                                onChange={(e) => setNewOccasion(e.target.value)}
                                className="w-full px-4 py-2 border border-neutral-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                                disabled={isLoading}
                            />
                            <button
                                onClick={() => addOccasion()}
                                className="w-full p-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
                                disabled={isLoading || !newOccasion.trim()}
                            >
                                Anlass erstellen
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                renderWelcomeScreen()
            )}
        </div>
    );

    // Sidebar Rendering
    const renderSidebar = () => (
        <div className="flex flex-col space-y-8 h-full">
            {/* Neuer Anlass */}
            <div className="bg-white rounded-xl shadow-sm p-6 flex flex-col">
                <h2 className="text-xl font-serif text-neutral-800 mb-4">Neuer Anlass</h2>
                <div className="space-y-2">
                    <input
                        type="text"
                        placeholder="z.B. Geburtstag, Weihnachten..."
                        value={newOccasion}
                        onChange={(e) => setNewOccasion(e.target.value)}
                        className="w-full px-4 py-2 border border-neutral-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                        disabled={isLoading}
                    />
                    <button
                        onClick={() => addOccasion()}
                        className="w-full p-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
                        disabled={isLoading || !newOccasion.trim()}
                    >
                        Anlass erstellen
                    </button>
                </div>
            </div>

            {/* Meine Listen */}
            {Object.keys(wishlists).filter(list => list !== '_temp_products').length > 0 && (
                <div className="bg-white rounded-xl shadow-sm p-6 flex-grow overflow-auto">
                    <h2 className="text-xl font-serif text-neutral-800 mb-4">Meine Listen</h2>
                    <div className="space-y-2">
                        {Object.keys(wishlists)
                            .filter(list => list !== '_temp_products')
                            .map((list) => (
                                <button
                                    key={list}
                                    className={`w-full text-left px-4 py-3 rounded-lg transition-colors ${
                                        currentList === list ? 'bg-orange-500 text-white' : 'text-neutral-700 hover:bg-orange-50'
                                    }`}
                                    onClick={() => setCurrentList(list)}
                                    disabled={isLoading}
                                >
                                    <div className="flex items-center gap-3">
                                        <Gift className="h-5 w-5" />
                                        <span>{list}</span>
                                    </div>
                                </button>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );

    // Welcome Screen Rendering
    const renderWelcomeScreen = () => (
        <div className="text-center py-12">
            <Gift className="h-16 w-16 text-orange-300 mx-auto mb-4" />
            <p className="text-neutral-600 mb-6">
                Erstelle eine neue Wunschliste oder lade eine bestehende Wunschliste mit einem Code.
            </p>
            <div className="max-w-xl mx-auto space-y-6">
                <div className="bg-white rounded-xl shadow-sm p-6">
                    <h2 className="text-xl font-serif text-neutral-800 mb-4">Bestehende Wunschliste laden</h2>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <input
                            type="text"
                            placeholder="Wunschlisten-Code eingeben"
                            value={inputCode}
                            onChange={(e) => setInputCode(e.target.value)}
                            className="flex-1 px-4 py-2 border border-neutral-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                            disabled={isLoading}
                        />
                        <button
                            onClick={() => loadWishlist(inputCode)}
                            className="p-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
                            disabled={isLoading || !inputCode.trim()}
                        >
                            Wunschliste laden
                        </button>
                    </div>
                </div>
                <div className="bg-white rounded-xl shadow-sm p-6">
                    <h2 className="text-xl font-serif text-neutral-800 mb-4">Neue Wunschliste erstellen</h2>
                    <button
                        onClick={createWishlist}
                        className="p-4 w-full bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
                        disabled={isLoading}
                    >
                        Neue Wunschliste erstellen
                    </button>
                </div>
            </div>
        </div>
    );

    // Hauptbereich zum Hinzufügen eines Produkts
    const renderProductAddition = () => (
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="flex items-center gap-2 text-lg font-serif text-neutral-800 mb-4">
                    <Search className="h-5 w-5 text-orange-500" />
                    Produkt suchen
                </h3>
                <ProductSearch
                    addProduct={addProduct}
                    disabled={isLoading}
                    ajaxUrl={window.awpGlobal.ajax_url}
                    nonce={window.awpGlobal.nonce}
                />
            </div>

            <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="flex items-center gap-2 text-lg font-serif text-neutral-800 mb-4">
                    <ExternalLink className="h-5 w-5 text-orange-500" />
                    Externes Produkt hinzufügen
                </h3>
                <div className="w-full">
                    <ExternalProductForm
                        addProduct={addProduct}
                        disabled={isLoading}
                    />
                </div>
            </div>
        </div>
    );

    // Hauptrender-Methode
    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-orange-100">
            {renderHeader()}

            <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
                {code ? (
                    <div className="grid grid-cols-12 gap-8">
                        {/* Sidebar */}
                        <div className="col-span-12 lg:col-span-3 h-full">
                            {renderSidebar()}
                        </div>

                        {/* Main Content */}
                        <div className="col-span-12 lg:col-span-9">
                            {renderMainSection()}
                            {currentList && renderProductAddition()}
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            {renderWelcomeScreen()}
                        </div>
                    </div>
                )}
            </main>

            {showTempProductsModal && (
                <TempProductsManager
                    tempProducts={tempProducts}
                    wishlists={wishlists}
                    onMove={moveProducts}
                    onClose={() => setShowTempProductsModal(false)}
                    onCreateList={handleCreateListFromTemp}
                />
            )}

            {isLoading && (
                <div className="awp-loading-overlay" aria-live="assertive" aria-busy="true">
                    <div className="awp-loading-spinner">
                        <div className="awp-spinner"></div>
                        <span>Wird geladen...</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WishlistManager;
export { StorageManager };

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Gift, X, Check, ExternalLink } from 'lucide-react';
import '../WishlistManager.css';

const SharedWishlist = () => {
    const { code } = useParams();
    const [wishlists, setWishlists] = useState({});
    const [currentList, setCurrentList] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Funktion zum Laden der geteilten Wunschliste
    const loadSharedWishlist = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const response = await fetch(window.awpGlobal.ajax_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    action: 'awp_handle_wishlist',
                    action_type: 'load',
                    code: code,
                    nonce: window.awpGlobal.nonce
                }),
            });

            const data = await response.json();

            if (data.success) {
                // Wenn eine Liste über die URL angegeben ist, anzeigen
                const params = new URLSearchParams(window.location.search);
                const occasion = params.get('occasion');
                if (occasion && data.data[occasion]) {
                    setCurrentList(occasion);
                    setWishlists({ [occasion]: data.data[occasion] });
                } else {
                    // Standardmäßig die erste Liste anzeigen
                    const firstList = Object.keys(data.data).find(list => list !== '_temp_products') || '';
                    setCurrentList(firstList);
                    setWishlists({ [firstList]: data.data[firstList] });
                }
            } else {
                throw new Error(data.data || 'Keine Wunschliste gefunden.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Funktion zum Aktualisieren des "Erfüllt"-Status
    const toggleBoughtStatus = async (productId) => {
        try {
            setIsLoading(true);
            setError(null);

            const response = await fetch(window.awpGlobal.ajax_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    action: 'awp_handle_wishlist',
                    action_type: 'toggle_bought',
                    code: code,
                    product_id: productId,
                    nonce: window.awpGlobal.nonce
                }),
            });

            const data = await response.json();

            if (data.success) {
                // Aktualisiere den lokalen Zustand ohne erneutes Laden
                setWishlists(prevWishlists => {
                    const updatedList = { ...prevWishlists };
                    const listItems = updatedList[currentList].map(item => {
                        if (item.id === productId) {
                            return { ...item, bought: !item.bought };
                        }
                        return item;
                    });
                    updatedList[currentList] = listItems;
                    return updatedList;
                });
            } else {
                throw new Error(data.data || 'Fehler beim Aktualisieren des Status.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (code) {
            loadSharedWishlist();
        } else {
            setError('Kein gültiger Code angegeben.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    const renderHeader = () => (
        <header className="bg-white shadow-md">
            <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center gap-3">
                    <Gift className="h-8 w-8 text-orange-500" />
                    <h1 className="text-3xl font-serif text-neutral-800">Wunschliste</h1>
                </div>
                <button
                    onClick={() => navigate('/wunschliste')}
                    className="p-2 hover:bg-orange-50 rounded-full transition-colors"
                    aria-label="Zurück zur Verwaltung"
                >
                    <X className="h-5 w-5 text-neutral-600" />
                </button>
            </div>
        </header>
    );

    const renderWishlistContent = () => {
        if (!currentList) {
            return (
                <div className="text-center py-12">
                    <Gift className="h-16 w-16 text-neutral-300 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-neutral-800 mb-2">Keine Liste verfügbar</h3>
                    <p className="text-neutral-500 mb-6">Die Wunschliste enthält keine Produkte.</p>
                </div>
            );
        }

        return (
            <div className="bg-white rounded-xl shadow-sm p-8">
                {/* Intro */}
                <div className="mb-6">
                    <h2 className="text-2xl font-serif text-neutral-800 mb-2">{currentList}</h2>
                    <p className="text-neutral-600">
                        Willkommen zu dieser Wunschliste! Wenn du einen Wunsch erfüllen möchtest, kannst du ihn als "erfüllt" markieren.
                    </p>
                </div>

                {wishlists[currentList]?.length > 0 ? (
                    <SharedWishlistItems
                        items={wishlists[currentList]}
                        onToggleBought={toggleBoughtStatus}
                    />
                ) : (
                    <div className="text-center py-12">
                        <Gift className="h-16 w-16 text-neutral-300 mx-auto mb-4" />
                        <h3 className="text-lg font-medium text-neutral-800 mb-2">Keine Produkte in dieser Liste</h3>
                        <p className="text-neutral-500 mb-6">Es sind keine Produkte in dieser Wunschliste vorhanden.</p>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-orange-100">
            {renderHeader()}

            <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
                {isLoading ? (
                    <div className="flex justify-center items-center min-h-[50vh]">
                        <div className="awp-loading-spinner">
                            <div className="awp-spinner"></div>
                            <span>Wird geladen...</span>
                        </div>
                    </div>
                ) : error ? (
                    <div className="text-center py-12">
                        <h3 className="text-lg font-medium text-neutral-800 mb-2">Fehler</h3>
                        <p className="text-neutral-500 mb-6">{error}</p>
                    </div>
                ) : (
                    <div>
                        {renderWishlistContent()}
                    </div>
                )}
            </main>
        </div>
    );
};

// Komponente für die Anzeige der Wishlist Items
const SharedWishlistItems = ({ items, onToggleBought }) => {
    return (
        <ul className="space-y-4">
            {items.map(item => (
                <li
                    key={item.id}
                    className={`p-4 rounded-lg flex flex-col sm:flex-row items-start sm:items-center justify-between shadow-sm ${item.bought ? 'bg-green-50' : 'bg-neutral-50'}`}
                >
                    <div className="flex items-center gap-4 flex-1">
                        {item.imageUrl && (
                            <img
                                src={item.imageUrl}
                                alt={item.productName || item.name}
                                className="w-16 h-16 object-cover rounded-lg"
                            />
                        )}
                        <div>
                            <h3 className="text-lg font-medium text-neutral-800">{item.productName || item.name}</h3>
                            {item.description && (
                                <p className="text-neutral-500 text-sm">{item.description}</p>
                            )}
                            {item.productUrl || item.url ? (
                                <a
                                    href={item.productUrl || item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center gap-1 text-orange-500 hover:underline mt-1 text-sm"
                                >
                                    <ExternalLink className="h-4 w-4" />
                                    <span>Zum Produkt</span>
                                </a>
                            ) : null}
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-0">
                        <button
                            className={`px-4 py-2 rounded-lg transition-colors ${
                                item.bought
                                    ? 'bg-green-500 text-white hover:bg-green-600'
                                    : 'bg-orange-500 text-white hover:bg-orange-600'
                            }`}
                            onClick={() => onToggleBought(item.id)}
                        >
                            {item.bought ? (
                                <span className="flex items-center gap-2">
                                    <Check className="h-5 w-5" />
                                    Erfüllt
                                </span>
                            ) : (
                                'Als erfüllt markieren'
                            )}
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default SharedWishlist;

// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

const container = document.getElementById('awp-wishlist-app');
if (container) {
    const root = createRoot(container);
    root.render(<App />);
} else {
    console.error('Das Container-Element #awp-wishlist-app wurde nicht gefunden.');
}
